export const configurableScaleScopes = {
    props: {
        scale: {type: Object, required: true},
        disabled: {type: Boolean, default: false},
    },
    methods: {
        saveScope(scope){
            this.$emit('save-scope', scope);
        },
        deleteScope(scope){
            this.$emit('delete-scope', scope);
        },
        orderChanged(event){
            const newPositionIndex = event.list.map(e => e.id).indexOf(event.targetObject.id);
            event.targetObject.order = newPositionIndex+1;

            this.$emit('save-scope', event.targetObject);
        }
    }
}